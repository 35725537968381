
import {defineComponent, ref, computed, PropType, onMounted, onUnmounted, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import Item from "@/views/components/directory/item.vue";
import flattenDeep from 'lodash/flattenDeep';

export default defineComponent({
	emits: ["update-options", "is-options-error"],
	props: {
		title: {
			type: String,
			default: null
		},
		options: {
			type: Object as PropType<{[key: string]: any}>,
			required: true
		},
		isReadOnly: {
			type: Boolean,
			default: false
		},
		placeholders: {
			type: Object as PropType<{[key: string]: any}>
		}
	},
	components: {
		Item
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const items = ref([] as string[][]);
		const optionsError = ref();
		const isOptionsError = computed(() =>
			flattenDeep(items.value.map(item => item[0])).some(item => item.length === 0) ? t("form.error.allRequired") : ""
		);

		const checkList = () => {
			items.value = Object.entries(props.options);

			if(props.isReadOnly) {
				optionsError.value = isOptionsError.value;
				emit("is-options-error", isOptionsError.value);
			}
		}

		onMounted(() => checkList());

		onUnmounted(() => checkList());

		watch(() => props.options, () => checkList());

		const updateItems = () => {
			let options = {};
			items.value.map(item => {
				options = Object.assign(options, {[item[0]]: item[1]});
			});

			optionsError.value = isOptionsError.value;

			emit("update-options", options);
			emit("is-options-error", isOptionsError.value);
		}

		const addItem = () => {
			items.value.push(["", ""]);
			updateItems();
		}

		const removeItem = (index: number) => {
			if (index > -1)
				items.value.splice(index, 1);

			updateItems();

			return items.value;
		};

		return {t, items, optionsError, updateItems, addItem, removeItem}
	}
});
