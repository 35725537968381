
import {defineComponent, ref, computed, PropType, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {Resources, Tags, TypeResources} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';
import List from "@/views/components/directory/list.vue";

export default defineComponent({
	emits: ["dialog-closed", "item-added"],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		item: {
			type: Object as PropType<Resources.Resource>,
			required: true
		},
		typeResource: {
			type: Object as PropType<TypeResources.TypeResource>
		}
	},
	components: {
		List
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset, values, setValues} = useForm();

		const selectedTag = ref({} as Tags.Tag|null)
		// const tags = computed(() => store.state.dictionaries.tags as Tags.Tag[]);
		const tagsFree = computed(() => store.state.dictionaries.tagsFree as Tags.Tag[]);
		function selectTag(selected: Tags.Tag) {
			selectedTag.value = selected ?? {};
		}

		const { value: selectedTypeResource, errorMessage: typeResourcesError } = useField<TypeResources.TypeResource>('selectedTypeResource', 'required');
		const typeResources = computed(() => store.state.dictionaries.typeResources as TypeResources.TypeResource[]);
		function selectTypeResource(selected: TypeResources.TypeResource) {
			selectedTypeResource.value = selected ?? {};

			description.value = {};
			Object.keys(selected.description_pattern ?? {}).map(key => {
				description.value = Object.assign(description.value, {[key]: ""});
			});
		}

		const { value: name, errorMessage: nameError } = useField<string>('name', 'required');
		const { value: external_id, errorMessage: externalIdError } = useField<string>('externalId');
		const { value: external_id_type, errorMessage: externalIdTypeError } = useField<string>('externalIdType');

		const personal_avatar = ref("");

		const description = ref({} as {[key: string]: any});
		const descriptionError = ref<string>("");

		const set = async () => {
			await store.dispatch('dictionaries/getTagsFree');

			if(props.item.name) {
				setValues({
					name: props.item?.name || "",
				});
			}
			if(props.item.external_id) {
				setValues({
					external_id: props.item.external_id || "",
				});
			}
			if(props.item.external_id_type) {
				setValues({
					external_id_type: props.item.external_id_type || ""
				});
			}

			// if(props.item.id_tag) {
			// 	const findTag = tags.value.find(tag => tag.id === props.item.id_tag);

			// 	if(findTag)
			// 		selectedTag.value = findTag;
			// }

			if(props.item.id_type_res || props.typeResource) {
				const findTypeResource = typeResources.value.find(typeResource => typeResource.id === (props.item.id_type_res || props.typeResource?.id));

				if(findTypeResource) {
					selectedTypeResource.value = findTypeResource;

					description.value = {};

					Object.keys(findTypeResource.description_pattern ?? {}).map(key =>
						description.value = Object.assign(description.value, {[key]: ""}));

					if(props.item.description)
						description.value = Object.assign(description.value, props.item.description);
				}
			}

			personal_avatar.value = props.item.personal_avatar || "";
		}

		set();

		watch(() => props.open, () => {
			props.open
				? set()
				: selectedTag.value = null;
		});

		const isLoading = ref(false);
		const service = injectOrThrow<Resources.ResourceService>('Resources.ResourceService');

		const add = handleSubmit(async () => {
			if(descriptionError.value.length) return;

			isLoading.value = true;

			const id_tag = selectedTag.value?.id ? {id_tag: selectedTag.value.id} : {};

			try {
				await service.add({
					id: 0,
					name: values.name,
					external_id: values.external_id,
					external_id_type: values.external_id_type,
					description: description.value,
					id_type_res: selectedTypeResource.value.id,
					...id_tag,
					personal_avatar: personal_avatar.value,
					id_enterprise: store.state.globals.enterpriseId
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.added", { name: ucFirst(t("dictionary.resource")) })});
				emit("item-added");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = () => {
			emit('dialog-closed');
			handleReset();

			personal_avatar.value = "";
			description.value = {};
		}

		return {
			t,
			tagsFree, selectedTag, selectTag,
			typeResources, selectedTypeResource, typeResourcesError, selectTypeResource,
			name, nameError,
			external_id, externalIdError,
			external_id_type, externalIdTypeError,
			personal_avatar,
			description, descriptionError,
			isLoading, add, closeModal
		}
	}
})
