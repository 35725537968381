import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row mx-n1" }
const _hoisted_2 = { class: "col py-0 px-1" }
const _hoisted_3 = { class: "row mx-n1" }
const _hoisted_4 = {
  key: 0,
  class: "app-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["py-0 px-1", _ctx.isReadOnly ? "col-12" : "col-5"])
        }, [
          (_ctx.isReadOnly)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.key), 1))
            : (_openBlock(), _createBlock(_component_app_text_field, {
                key: 1,
                name: "key",
                modelValue: _ctx.key,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.key) = $event)),
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit("update-key", _ctx.key))),
                placeholder: _ctx.t("form.placeholder.addName"),
                outlined: ""
              }, null, 8, ["modelValue", "placeholder"]))
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["py-0 px-1", _ctx.isReadOnly ? "col-12" : "col-7"])
        }, [
          _createVNode(_component_app_text_field, {
            name: "value",
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit("update-value", _ctx.value))),
            placeholder: _ctx.placeholder,
            outlined: ""
          }, null, 8, ["modelValue", "placeholder"])
        ], 2)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["col-auto py-0 px-1", _ctx.isReadOnly ? "pt-5" : ""])
    }, [
      _createVNode(_component_app_button, {
        icon: "",
        class: "mt-1 text-red",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit("remove-item")))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_app_icon, {
            icon: "cross",
            size: 14
          })
        ]),
        _: 1
      })
    ], 2)
  ]))
}