import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col py-0" }
const _hoisted_3 = { class: "subtitle-1" }
const _hoisted_4 = { class: "col-auto py-0" }
const _hoisted_5 = { class: "mb-6" }
const _hoisted_6 = { class: "mb-6 relative" }
const _hoisted_7 = { class: "app-label" }
const _hoisted_8 = { class: "mb-6 relative" }
const _hoisted_9 = { class: "app-label" }
const _hoisted_10 = {
  key: 0,
  class: "app-input__details absolute"
}
const _hoisted_11 = { class: "error--text" }
const _hoisted_12 = { class: "mb-6" }
const _hoisted_13 = { class: "app-label" }
const _hoisted_14 = { class: "mb-6" }
const _hoisted_15 = { class: "mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_autocomplete = _resolveComponent("app-autocomplete")!
  const _component_list = _resolveComponent("list")!
  const _component_app_file_upload = _resolveComponent("app-file-upload")!
  const _component_app_dialog = _resolveComponent("app-dialog")!

  return (_openBlock(), _createBlock(_component_app_dialog, {
    open: _ctx.open,
    width: 450,
    onDialogClosed: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("button.edit")) + " " + _toDisplayString(_ctx.t("dictionary.resource")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_app_button, {
            icon: "",
            onClick: _ctx.closeModal,
            class: "ma-n3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_icon, {
                icon: "cross",
                size: 16
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.edit && _ctx.edit(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_app_text_field, {
            name: "name",
            modelValue: _ctx.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            label: _ctx.t("dictionary.name"),
            placeholder: _ctx.t("form.placeholder.editName"),
            outlined: "",
            errorMessage: _ctx.nameError
          }, null, 8, ["modelValue", "label", "placeholder", "errorMessage"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t("dictionary.tag")), 1),
          ((_ctx.selectedTag && _ctx.selectedTag.id) || _ctx.tagsFree.length)
            ? (_openBlock(), _createBlock(_component_app_select, {
                key: 0,
                items: _ctx.tagsFree,
                selected: _ctx.selectedTag,
                onSelectItem: _ctx.selectTag,
                "item-name": "comment"
              }, null, 8, ["items", "selected", "onSelectItem"]))
            : (_openBlock(), _createBlock(_component_app_button, {
                key: 1,
                block: "",
                to: {name: "tags"},
                class: _normalizeClass({"mt-2": _ctx.selectedTag && _ctx.selectedTag.id})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("form.error.noTagSelection")) + ". " + _toDisplayString(_ctx.t("button.add")) + " " + _toDisplayString(_ctx.t("dictionary.tag")), 1)
                ]),
                _: 1
              }, 8, ["class"]))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.t("dictionary.typeResource")), 1),
          _createVNode(_component_app_autocomplete, {
            items: _ctx.typeResources,
            selected: _ctx.selectedTypeResource,
            onItemSelected: _ctx.selectTypeResource
          }, null, 8, ["items", "selected", "onItemSelected"]),
          (_ctx.typeResourcesError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.typeResourcesError), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        (Object.keys(_ctx.description).length > 0)
          ? (_openBlock(), _createBlock(_component_list, {
              key: 0,
              placeholders: _ctx.selectedTypeResource?.description_pattern,
              title: _ctx.t("dictionary.description"),
              options: _ctx.description,
              onUpdateOptions: _cache[1] || (_cache[1] = ($event: any) => (_ctx.description = $event)),
              onIsOptionsError: _cache[2] || (_cache[2] = ($event: any) => (_ctx.descriptionError = $event)),
              isReadOnly: ""
            }, null, 8, ["placeholders", "title", "options"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.t("dictionary.avatar")), 1),
          _createVNode(_component_app_file_upload, {
            avatar: _ctx.personal_avatar,
            onUpdateFile: _cache[3] || (_cache[3] = ($event: any) => (_ctx.personal_avatar = $event))
          }, null, 8, ["avatar"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_app_text_field, {
            name: "externalId",
            modelValue: _ctx.external_id,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.external_id) = $event)),
            label: _ctx.t("dictionary.externalId"),
            placeholder: _ctx.t("form.placeholder.addExternalId"),
            outlined: "",
            errorMessage: _ctx.externalIdError
          }, null, 8, ["modelValue", "label", "placeholder", "errorMessage"])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_app_text_field, {
            name: "externalIdType",
            modelValue: _ctx.external_id_type,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.external_id_type) = $event)),
            label: _ctx.t("dictionary.externalIdType"),
            placeholder: _ctx.t("form.placeholder.addExternalIdType"),
            outlined: "",
            errorMessage: _ctx.externalIdTypeError
          }, null, 8, ["modelValue", "label", "placeholder", "errorMessage"])
        ]),
        _createVNode(_component_app_button, {
          block: "",
          large: "",
          color: "bg-blue text-white",
          onClick: _ctx.edit,
          loading: _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("button.save")), 1)
          ]),
          _: 1
        }, 8, ["onClick", "loading"])
      ], 32)
    ]),
    _: 1
  }, 8, ["open", "onDialogClosed"]))
}