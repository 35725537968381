
import {defineComponent, ref, computed, watch, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {Resources, Tags, TypeResources} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';
import List from "@/views/components/directory/list.vue";

export default defineComponent({
	emits: ["dialog-closed", "item-changed"],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		item: {
			type: Object as PropType<Resources.Resource>,
			required: true
		}
	},
	components: {
		List
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset, setValues, values} = useForm();

		const selectedTag = ref({} as Tags.Tag|null)
		const tags = computed(() => store.state.dictionaries.tags as Tags.Tag[]);
		const tagsFree = computed(() => store.state.dictionaries.tagsFree as Tags.Tag[]);
		function selectTag(selected: Tags.Tag) {
			selectedTag.value = selected ?? {};
		}

		const { value: selectedTypeResource, errorMessage: typeResourcesError } = useField<TypeResources.TypeResource>('selectedTypeResource', 'required');
		const typeResources = computed(() => store.state.dictionaries.typeResources as TypeResources.TypeResource[]);
		function selectTypeResource(selected: TypeResources.TypeResource) {
			selectedTypeResource.value = selected ?? {};

			description.value = {};
			Object.keys(selected.description_pattern ?? {}).map(key => {
				description.value = Object.assign(description.value, {[key]: ""});
			});
		}

		const { value: name, errorMessage: nameError } = useField<string>('name', 'required');
		const { value: external_id, errorMessage: externalIdError } = useField<string>('external_id');
		const { value: external_id_type, errorMessage: externalIdTypeError } = useField<string>('external_id_type');

		const personal_avatar = ref("");

		const description = ref({} as {[key: string]: any});
		const descriptionError = ref<string>("");

		const set = async () => {
			await store.dispatch('dictionaries/getTags');
			await store.dispatch('dictionaries/getTagsFree');

			setValues({
				name: props.item?.name || "",
				external_id: props.item?.external_id || "",
				external_id_type: props.item?.external_id_type || ""
			});

			if(props.item.id_tag) {
				const findTag = tags.value.find(tag => tag.id === props.item.id_tag);

				if(findTag)
					selectedTag.value = findTag;
			}

			if(props.item.id_type_res) {
				const findTypeResource = typeResources.value.find(typeResource => typeResource.id === props.item.id_type_res);

				if(findTypeResource) {
					selectedTypeResource.value = findTypeResource;

					description.value = {};

					if(props.item.description) {
						Object.keys(findTypeResource.description_pattern ?? {}).map(key =>
							description.value = Object.assign(description.value, {[key]: ""}));

						description.value = Object.assign(description.value, typeof props.item.description === 'string' ? JSON.parse(props.item.description) : props.item.description );
					}
				}
			}

			personal_avatar.value = props.item.personal_avatar || "";
		}

		set();

		watch(() => props.open, () => {
			props.open
				? set()
				: selectedTag.value = null;
		});

		const isLoading = ref(false);
		const service = injectOrThrow<Resources.ResourceService>('Resources.ResourceService');

		const edit = handleSubmit(async () => {
			if(descriptionError.value.length) return;

			isLoading.value = true;

			const id_tag = selectedTag.value?.id ? {id_tag: selectedTag.value.id} : {};

			try {
				await service.edit({
					id: props.item.id,
					name: values.name,
					external_id: values.external_id,
					external_id_type: values.external_id_type,
					description: description.value,
					id_type_res: selectedTypeResource.value.id,
					...id_tag,
					personal_avatar: personal_avatar.value,
					id_enterprise: store.state.globals.enterpriseId
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.edited", { name: ucFirst(t("dictionary.resource")) })});
				emit("item-changed");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = () => {
			emit('dialog-closed');
			handleReset();
			description.value = {};
		};

		return {
			t,
			tagsFree, selectedTag, selectTag,
			typeResources, selectedTypeResource, typeResourcesError, selectTypeResource,
			name, nameError,
			external_id, externalIdError,
			external_id_type, externalIdTypeError,
			personal_avatar,
			description, descriptionError,
			isLoading, edit, closeModal
		}
	}
})
