
import {defineComponent, ref, onMounted, watch, PropType} from 'vue';
import {useI18n} from 'vue-i18n';

export default defineComponent({
	emits: ['update-key', 'update-value', 'remove-item'],
	props: {
		item: {
			type: Array as PropType<string[]>,
			required: true
		},
		isReadOnly: {
			type: Boolean,
			default: false
		},
		placeholders: {
			type: Object as PropType<{[key: string]: any}>
		}
	},
	setup(props) {
		const { t } = useI18n();
		const key = ref<string>();
		const value = ref<string>();
		const placeholder = ref<string>();

		const changeItem = () => {
			key.value = props.item[0] ?? "";
			value.value = String(props.item[1]) ?? "";

			placeholder.value = props.placeholders ? props.placeholders[props.item[0]] : t("form.placeholder.addDescription");
		}

		onMounted(() => changeItem());

		watch(() => props.item, () => changeItem());

		return {t, key, value, placeholder};
	}
});
